.numberCircle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 5px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.nav-logo {
  background-size: 28px 28px;
  height: 45px;
}
.header-logo {
  background-size: 28px 28px;
  width: 50px;
}
.footer .footer-logo {
  background-size: 80px 80px;
  height: 80px !important;
  margin-bottom: 20px !important;
}

.theme-light .header .header-logo {
  background-image: url(/images/preload-logo.png);
}
.theme-light .menu .nav-logo {
  background-image: url(/images/preload-logo.png);
}
.theme-light .footer .footer-logo {
  background-image: url(/images/preload-logo.png);
}

.theme-dark .header .header-logo {
  background-image: url(/images/preload-logo.png);
}
.theme-dark .menu .nav-logo {
  background-image: url(/images/preload-logo.png);
}
.theme-dark .footer .footer-logo {
  background-image: url(/images/preload-logo.png);
}
