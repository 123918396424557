.Badge {
  display: inline-block;
  margin: 0 !important;
  border-radius: 30px;
  padding-right: 15px;
  position: relative;
  width: 30px;
  height: 30px;
}
.Badge span {
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: inherit !important;
  transform: translate(-50%, -50%);
  display: inline-block;
}
