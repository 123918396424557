.date-picker-container {
    overflow: visible !important;
    line-height: normal !important;
}

.date-picker-container input {
    min-width: 240px !important;
}

.date-picker-container div.react-datepicker__time-container,
.date-picker-container div.react-datepicker__time-container div {
    width: 50px !important;
}

.date-picker-container .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.3rem !important;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.1rem !important;
}